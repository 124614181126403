import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import './ForgotPasswordForm.css';
import { getForgotPasswordToken } from '../../utils/apis/authentication';
import { withRouter } from 'react-router-dom';
const FormItem = Form.Item;

class ForgotPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      buttonDisabled: '',
      loading: false,
      emailCheck: ''
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      emailCheck: ''
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        getForgotPasswordToken({
          email: values.email,
          host: window.location.host
        })
          .then(res => {
            if (res.status === 200) {
              this.setState({
                loading: false,
                buttonDisabled: 'disabled',
                emailCheck: 'found'
              });
            } else {
              this.setState({
                loading: false,
                emailCheck: 'notFound'
              });
            }
          })
          .catch(error => {});
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Please enter your Email!'
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={'Email'} />)}
        </FormItem>
        <Button type="primary" htmlType="submit" className="forgot-form-button" loading={this.state.loading} disabled={this.state.buttonDisabled}>
          Confirm
        </Button>
        <Button
          type="default"
          className="back-button"
          onClick={() => {
            this.props.history.push('/');
          }}
        >
          Back
        </Button>
        <FormItem>
          {(this.state.emailCheck === 'found' && (
            <Alert message={'Instructions to reset your password have been emailed to you. Please check your email.'} type="success" showIcon />
          )) ||
            (this.state.emailCheck === 'notFound' && (
              <Alert message={'Email is not found as an existing user in the system.'} type="error" showIcon />
            ))}
        </FormItem>
      </Form>
    );
  }
}

export default withRouter(Form.create()(ForgotPasswordForm));
