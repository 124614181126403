import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Button, Modal, Tabs, notification } from 'antd';

import { withAppContext } from 'context/AppContext';
import { putUpdateProperty } from 'utils/apis/property';
import { putUpdateRoomType } from 'utils/apis/roomtype';

import BasicDetailsCard from './components/BasicDetailsCard/BasicDetailsCard';
import BookingEngineConfigDetailsCard from './components/BookingEngineConfigDetailsCard/BookingEngineConfigDetailsCard';

import styles from './BookingEngineConfiguration.module.css';
import SyncCard from './components/SyncCard/SyncCard';
import FinePrintInfoCard from './components/FinePrintInfoCard/FinePrintInfoCard';
import AddOn from './components/AddOnCard/AddOn';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;
class BookingEngineConfiguration extends Component {
  constructor() {
    super();

    this.state = {
      isSaving: false,
      files: [],
      rateSettings: {}
    };
  }

  extractBasicDetailsData = () => {
    const { data } = this.props;
    return {
      bookingEngine: data[0].property.bookingEngine
        ? data[0].property.bookingEngine
        : {
            displayName: '',
            propertyDetails: '',
            nonSmoking: false,
            cashDeposit: false,
            bankDeposit: false,
            houseRules: '',
            files: []
          }
    };
  };

  extractRoomTypeData = () => {
    const { data } = this.props;

    return {
      roomTypes:
        data && data.length > 0
          ? data.map(roomType => ({
              key: roomType._id,
              name: roomType.name,
              externalDisplayName: roomType.bookingEngine ? roomType.bookingEngine.externalDisplayName : roomType.name,
              sync: roomType.bookingEngine ? roomType.bookingEngine.sync : false,
              rate: roomType.rate
                ? roomType.rate.length > 1
                  ? { ...roomType.rate.find(rate => rate.code === 'HBE'), stdRateId: roomType.rate.find(rate => rate.code === 'STD')._id } // putting stdRateId for derived rate to get parent id
                  : roomType.rate.find(rate => rate.code === 'STD')
                : { weekday: 0, weekend: 0 }
            }))
          : [],
      propSync: data === undefined ? false : data[0].property.bookingEngine ? data[0].property.bookingEngine.propSync : true
    };
  };

  extractFinePrintInfoData = () => {
    const { data } = this.props;
    return { finePrint: data[0].property.bookingEngine ? data[0].property.bookingEngine.finePrint : '' };
  };

  extractBookingEngineConfigDetailsData = () => {
    const { data } = this.props;
    return {
      displayName: data === undefined ? '' : data[0].property.bookingEngine ? data[0].property.bookingEngine.displayName : data[0].property.name
    };
  };

  extractAddOnData = () => {
    const { data } = this.props;

    return {
      roomTypes:
        data && data.length > 0
          ? data.map(roomType => ({
              key: roomType._id,
              cleaningFee: roomType.bookingEngine && roomType.bookingEngine.cleaningFee ? roomType.bookingEngine.cleaningFee : 0.0,
              extraGuest: roomType.bookingEngine
                ? roomType.bookingEngine.extraGuest
                : {
                    number: 0,
                    amount: 0
                  },
              capacity: roomType.capacity ? roomType.capacity.adult + roomType.capacity.children : 0,
              name: roomType.name
            }))
          : [],
      addOns:
        data[0] && data[0].property.addOns && data[0].property.addOns.length > 0
          ? data[0].property.addOns.map((addOn, i) => {
              return {
                activity: addOn.activity,
                amount: addOn.amount,
                key: i++
              };
            })
          : [],
      taxes:
        data[0] && data[0].property.bookingEngine && data[0].property.bookingEngine.taxes
          ? data[0].property.bookingEngine.taxes.map((items, i) => {
              return {
                taxType: items.taxType,
                amountType: items.amountType,
                taxAmount: items.amount,
                key: i
              };
            })
          : [],
      serviceFee:
        data[0] && data[0].property.bookingEngine && data[0].property.bookingEngine.serviceFee ? data[0].property.bookingEngine.serviceFee : 0
    };
  };

  handleOnSave = async () => {
    const { form, data } = this.props;
    let roomTypeUpdate = [];
    const payload = [form.getFieldsValue()].map(this.formatBookingEngineConfigDetails).map(this.formatAddOnsDetails);

    const roomTypePayload = [form.getFieldsValue()].map(this.formatRoomTypeSyncDetails);

    this.setState({
      isSaving: true
    });

    for (let i = 0; i < roomTypePayload[0].roomType.length; i++) {
      const updateRoomTypePromise = await putUpdateRoomType(roomTypePayload[0].roomType[i]._id, roomTypePayload[0].roomType[i]);
      roomTypeUpdate.push(updateRoomTypePromise);
    }
    const updatePropertyPromise = await putUpdateProperty(data[0].property._id, payload[0]);

    return Promise.all(roomTypeUpdate, [updatePropertyPromise])
      .then(() => {
        this.setState({
          isSaving: false
        });
        notification.success({
          message: intl.get('listings.bookingEngine.message.update').d('Your booking website configuration has been updated!')
        });
      })
      .catch(e => {
        this.setState({
          isSaving: false
        });
        notification.error({
          message: intl.get('listings.bookingEngine.message.updateError').d('Something went wrong and your configuration is not updated')
          // description: e.message
        });
      });
  };

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const onOkAction = this.handleOnSave;
        Modal.confirm({
          title: intl.get('listings.bookingEngine.message.updateTitle').d('Do you want to update this property?'),
          content: intl
            .get('listings.bookingEngine.message.updateContent')
            .d('Updating this property will affect all units and room types that tied to this property.'),
          onOk() {
            return onOkAction();
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnRateSettingsSave = payload => {
    this.setState({
      rateSettings: payload
    });
  };

  formatBookingEngineConfigDetails = formFields => {
    const { data } = this.props;
    return {
      ...formFields,
      bookingEngine: {
        displayName: formFields.displayName ? formFields.displayName : data[0].property.bookingEngine.displayName,
        propertyDetails: formFields.propertyDetails,
        nonSmoking: formFields.nonSmoking ? formFields.nonSmoking : false,
        cashDeposit: formFields.cashDeposit ? formFields.cashDeposit : false,
        bankDeposit: formFields.bankDeposit ? formFields.bankDeposit : false,
        houseRules: formFields.houseRules ? formFields.houseRules : '',
        finePrint: formFields.finePrint,
        propSync: formFields.propSync,
        files: formFields.files
          ? formFields.files
          : data[0].property.bookingEngine
          ? data[0].property.bookingEngine.files
            ? data[0].property.bookingEngine.files
            : []
          : []
      }
    };
  };

  formatRoomTypeSyncDetails = formFields => {
    const { data } = this.props;
    const { rateSettings } = this.state;
    let roomIds = [];
    let roomType = [];
    let cleaningFee = [];
    const keys = Object.keys(formFields.cleaningFees);

    keys.forEach(key => {
      formFields.roomType[key].forEach(roomId => {
        let obj = {};
        obj[roomId] = formFields.amounts[key];
        cleaningFee.push(obj);
      });
    });

    data.forEach(roomType => {
      roomIds.push(roomType._id);
    });

    for (let i = 0; i < roomIds.length; i++) {
      let clean = 0.0;
      cleaningFee.forEach(cf => {
        if (!!cf[roomIds[i]]) {
          clean = cf[roomIds[i]];
        }
      });
      const rates = Object.keys(rateSettings).length === 0 ? [] : rateSettings.find(rate => rate.rate.roomId === roomIds[i]);
      // for config data that does not update rate settings
      if (rates.length === 0) {
        let room = {
          bookingEngine: {
            sync: formFields[roomIds[i]].sync,
            externalDisplayName: formFields[roomIds[i]].externalDisplayName,
            extraGuest: {
              number: formFields[roomIds[i]].number,
              amount: formFields[roomIds[i]].amount
            },
            cleaningFee: clean
          },
          _id: roomIds[i]
        };

        roomType.push(room);
      } else {
        let room = {
          bookingEngine: {
            sync: formFields[roomIds[i]].sync,
            externalDisplayName: formFields[roomIds[i]].externalDisplayName,
            extraGuest: {
              number: formFields[roomIds[i]].number,
              amount: formFields[roomIds[i]].amount
            },
            cleaningFee: clean
          },
          rate: {
            ...rates.rate,
            roomType: roomIds[i],
            code: 'HBE',
            name: 'Hotel Booking Website Rate'
          },
          _id: roomIds[i]
        };

        roomType.push(room);
      }
    }
    return {
      ...formFields,
      roomType
    };
  };

  formatAddOnsDetails = formFields => {
    return {
      bookingEngine: {
        ...formFields.bookingEngine,
        taxes:
          formFields.keys &&
          formFields.keys.map(key => {
            return {
              taxType: formFields.taxType[key],
              amountType: formFields.amountType[key],
              amount: formFields.taxAmount[key]
            };
          }),
        serviceFee: formFields.serviceFee ? formFields.serviceFee : 0
      },
      addOns:
        formFields.addOns &&
        formFields.addOns.map(key => {
          return {
            activity: formFields.activity[key],
            amount: formFields.amount[key]
          };
        })
    };
  };

  render() {
    const { form, hasFetchedRoomTypes, shouldUpdateBasicDetails, currency, checkIsAdmin, checkIsAdminReadOnly } = this.props;
    const { isSaving } = this.state;
    return (
      <div>
        <Form onSubmit={this.handleOnFormSubmit} style={{ width: '100%' }}>
          <Tabs type="card" className={styles.unitTab} defaultActiveKey="1">
            <TabPane
              tab={intl.get('listings.unit.headerLabels.general').d('General Details')}
              key="1"
              className={styles.unitTabPane}
              forceRender={hasFetchedRoomTypes}
            >
              <BookingEngineConfigDetailsCard
                form={form}
                cardClassname={styles.cardContainer}
                defaultValues={this.extractBookingEngineConfigDetailsData()}
                shouldUpdate={shouldUpdateBasicDetails}
              />
              <SyncCard
                form={form}
                cardClassname={styles.cardContainer}
                defaultValues={this.extractRoomTypeData()}
                onSaved={this.handleOnRateSettingsSave}
              />

              <BasicDetailsCard
                form={form}
                defaultValues={this.extractBasicDetailsData()}
                shouldUpdate={shouldUpdateBasicDetails}
                checkIsAdmin={checkIsAdmin()}
              />
              <FinePrintInfoCard form={form} cardClassname={styles.cardContainer} defaultValues={this.extractFinePrintInfoData()} />
            </TabPane>

            <TabPane
              tab={intl.get('listings.bookingEngine.headerLabels.fees').d('Fees & Add-ons')}
              key="2"
              className={styles.unitTabPane}
              forceRender={hasFetchedRoomTypes}
            >
              <AddOn form={form} cardClassname={styles.cardContainer} defaultValues={this.extractAddOnData()} currency={currency} />
            </TabPane>
          </Tabs>
          <Row type="flex" justify="start" gutter={8}>
            <Col>
              <Button
                id="save-button3-editlisting"
                type="primary"
                className={styles.margin}
                size="large"
                htmlType="submit"
                loading={isSaving}
                disabled={checkIsAdminReadOnly()}
              >
                {isSaving ? intl.get('listings.unit.headerLabels.saving').d('Saving') : intl.get('listings.unit.headerLabels.save').d('Save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

BookingEngineConfiguration.propTypes = {
  form: PropTypes.object.isRequired,
  checkIsAdmin: PropTypes.func,
  data: PropTypes.object,
  hasFetchedRoomTypes: PropTypes.bool,
  currency: PropTypes.string
};

BookingEngineConfiguration.defaultProps = {
  checkIsAdmin: () => {},
  data: {},
  currency: 'RM',
  hasFetchedRoomTypes: false
};

// NOTE: The purpose of having this class is for performance so that it doesn't rerender everything whenever user input something.
class WrappedFormBookingEngineConfiguration extends Component {
  RenderForm;

  constructor() {
    super();
    this.state = {
      shouldUpdateBasicDetails: true,
      shouldUpdateLocation: true,
      shouldUpdateAmenities: true
    };
    const onFieldChangeAction = this.onFieldChange.bind(this);
    this.RenderForm = Form.create({
      onFieldsChange: onFieldChangeAction
    })(BookingEngineConfiguration);
  }

  onFieldChange = (props, fields) => {
    const { nonSmoking, cashDeposit, bankDeposit, files } = this.state;
    const basicDetailFields = ['displayName', 'propertyDetails', 'houseRules'];
    const newState = {
      shouldUpdateBasicDetails: false,
      shouldUpdateLocation: false,
      shouldUpdateAmenities: false,
      nonSmoking: !nonSmoking,
      cashDeposit: !cashDeposit,
      bankDeposit: !bankDeposit,
      files: files
    };
    if (basicDetailFields.includes(...Object.keys(fields))) {
      newState.shouldUpdateBasicDetails = true;
    }
    this.setState(newState);
  };

  render() {
    return <this.RenderForm {...this.props} {...this.state} />;
  }
}

BookingEngineConfiguration.propTypes = {
  checkIsAdmin: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.array,
  hasFetchedRoomTypes: PropTypes.bool
};

BookingEngineConfiguration.defaultProps = {
  checkIsAdmin: () => {},
  data: {},
  hasFetchedRoomTypes: false
};

export default withRouter(withAppContext(WrappedFormBookingEngineConfiguration));
