import React from 'react';
import { Layout, Select, Dropdown, Menu } from 'antd';
import './SingleViewLayout.css';
import Footer from './../../components/Footer/Footer';

const { Content } = Layout;

const SingleViewLayout = props => {
  // Removed language module from login page
  // const langMenu = (
  //   <Menu onClick={handleMenuClick} defaultSelectedKeys={[context.currentLocale]}>
  //     {languageOptions.map(option => (
  //       <Menu.Item key={option.key}>
  //         <img src={option.flagUrl} alt={option.label} style={{ marginRight: '15px', width: '20px', height: 'auto' }} />
  //         {option.label}
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  return (
    <Layout className={props.className + ' single-view-layout-bg'}>
      <Content>{props.children}</Content>
      <Footer />
    </Layout>
  );
};

export default SingleViewLayout;
