import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { jsPDF } from 'jspdf';

import { getRevenueShare } from 'utils/apis/revenueshare';

import { MONTHS } from 'utils/constants';
import { getConstantLabel, guard, numberWithCommas } from 'utils/general';
import intl from 'react-intl-universal';
import { LangContext } from '../../../../../../../../../../utils/LocaleProvider';

//don delete this import, needed for trigger !!
import { chFont } from '../../../../../../../../../../fonts/NotoSansSC-Regular-normal';
import { thFont } from '../../../../../../../../../../fonts/NotoSansThai-Regular-normal';

const DEFAULT_DOCUMENT_DOM = <Document />;

const DEFAULT_DOCUMENT_SIZE = 369;

const useFetchLabels = (packageTypeConstants, revenueShareConstants, payoutDetail) => {
  const getRevenueShareLabel = (revenueShareConstants, revenueShareType) => {
    return (
      getConstantLabel(revenueShareConstants, revenueShareType) ||
      getRevenueShare(revenueShareType).then(res => {
        return res.data.name;
      })
    );
  };

  const [packageTypeLabel, setPackageTypeLabel] = useState('');
  const [revenueShareLabel, setRevenueShareLabel] = useState('');

  useEffect(() => {
    const fetchLabels = async () => {
      const packageTypeLabel = guard(() => getConstantLabel(packageTypeConstants, payoutDetail.servicePackage.packageType), '-');
      const revenueShareLabel = await getRevenueShareLabel(revenueShareConstants, payoutDetail.servicePackage.revenueShare);

      setPackageTypeLabel(packageTypeLabel);
      setRevenueShareLabel(revenueShareLabel);
    };

    fetchLabels();
  }, [packageTypeConstants, revenueShareConstants, payoutDetail]);

  return { packageTypeLabel, revenueShareLabel };
};

const constructDataForPayoutTemplatePdf = (packageTypeLabel, revenueShareLabel, selectedHostImage, selectedOwnerName, payoutDetail) => {
  let calculation = '';
  let ownersRevenueSharePercentage = false;

  const getCurrency = (payoutDetailInfo = {}) => guard(() => payoutDetailInfo.unit.currency, 'RM');
  if (payoutDetail.servicePackage.calculations) {
    const calculate = payoutDetail.servicePackage.calculations.find(calculation => {
      return Object.values(calculation)[0];
    });
    if (calculate) {
      if (String(calculate.type) === '1') {
        calculation = `${intl.get('payout.pdfPayout.fixedAmount').d('Fixed Amount')} ${getCurrency(payoutDetail)} ${numberWithCommas(
          calculate.amount
        )}`;
      } else {
        calculation = `${calculate.ownerShare}`;
        ownersRevenueSharePercentage = true;
      }
    }
  }

  const allowDisplayTotalPayoutAmount =
    (payoutDetail.year >= new Date().getFullYear() && payoutDetail.month >= new Date().getMonth()) || payoutDetail.year > new Date().getFullYear();

  return {
    ownerName: selectedOwnerName,
    totalUnits: payoutDetail.totalUnits,
    propertyName: payoutDetail.property.name || '',
    unitName: payoutDetail.unit.name || '',
    currentCollectionToDate: numberWithCommas(payoutDetail.perUnitRevenue),
    certNo:
      payoutDetail.protection && payoutDetail.protection.certNo
        ? payoutDetail.protection.certNo
        : intl.get('payout.pdfPayout.notActivated').d('Not Activated'),
    servicePackageName: payoutDetail.servicePackage.name || '',
    servicePackageTypeLabel: packageTypeLabel,
    servicePackageUnits: payoutDetail.servicePackage.totalUnits,
    servicePackageAmount: `${intl.get('payout.pdfPayout.owner').d('Owner')} ${calculation}%`,
    revenueShareLabel,
    monthString: getConstantLabel(MONTHS, payoutDetail.month),
    year: payoutDetail.year,
    expenses: payoutDetail.expenses,
    otherExpensesData: payoutDetail.otherExpensesData ? payoutDetail.otherExpensesData.othersBreakdownDetails || [] : {},
    reservations: payoutDetail.reservations,
    ownersRevenueShare: numberWithCommas(Number(payoutDetail.borneExpenses.owner) + Number(payoutDetail.nettOwnersProfit)),
    ownersRevenueSharePercentage: ownersRevenueSharePercentage ? `(${calculation}%)` : '',
    totalPayoutAmount: allowDisplayTotalPayoutAmount ? '-' : `${getCurrency(payoutDetail)} ${numberWithCommas(payoutDetail.totalPayoutAmount)}`,
    totalOwnerBorneExpenses: numberWithCommas(payoutDetail.borneExpenses.owner),
    nettProfit: numberWithCommas(payoutDetail.perUnitRevenue),
    totalRevenue: payoutDetail.totalRevenue,
    numberofReservations: payoutDetail.reservations.length,
    hostImage: selectedHostImage,
    totalNetProfit: numberWithCommas(Number(payoutDetail.totalRevenue) - Number(payoutDetail.borneExpenses.shared))
  };
};

const generatePayoutDocument = (
  integrationSourceConstants,
  packageTypeLabel,
  revenueShareLabel,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges
) => {
  const getCurrency = payoutDetailInfo => guard(() => payoutDetailInfo.unit.currency, 'RM');
  const payoutData = {
    ...constructDataForPayoutTemplatePdf(packageTypeLabel, revenueShareLabel, selectedHostImage, selectedOwnerName, payoutDetail)
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      paddingBottom: 35,
      paddingTop: 35
    },
    image: {
      width: 80,
      height: 80
    },
    row: {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap'
    },
    heightOfTen: {
      height: 10
    },
    paddingBtm: {
      paddingBottom: 5
    },
    groupFields: {
      width: '100%',
      flexDirection: 'row',
      paddingLeft: 20,
      paddingBottom: 10,
      alignItems: 'stretch'
    },
    title: {
      textAlign: 'center',
      fontSize: 20
      // paddingTop: 15
    },
    fieldsOwnerDetails: {
      fontSize: 10,
      width: '80%',
      textAlign: 'left',
      paddingLeft: 30,
      paddingRight: 10,
      paddingTop: 10,
      whiteSpace: 'normal'
    },
    normalText: {
      fontSize: 10,
      paddingLeft: 20,
      paddingRight: 20
    },
    greyBlockText: {
      backgroundColor: '#E7E5E6',
      fontSize: 10,
      height: 20,
      marginLeft: 10,
      marginRight: 10,
      paddingLeft: 10,
      paddingRight: 10
    },
    blueBlockText: {
      backgroundColor: '#BAE3EC',
      fontSize: 10,
      height: 20,
      marginLeft: 10,
      marginRight: 10,
      paddingLeft: 10,
      paddingRight: 10
    },
    noReservationText: {
      textAlign: 'center',
      paddingTop: '10',
      fontStyle: 'italic',
      fontSize: 20,
      color: 'grey'
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      alignItems: 'stretch',
      borderColor: 'black'
    },
    footer: {
      color: '#E7E5E6',
      textAlign: 'center',
      height: '3%',
      position: 'absolute',
      bottom: 0
    }
  });

  const headerDetails = (
    <View>
      <View style={styles.groupFields}>
        <View>
          <Image style={styles.image} src={payoutData.hostImage} />
        </View>
        <View style={styles.fieldsOwnerDetails}>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.ownerName').d('Owner Name')}</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{payoutData.ownerName}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalUnit').d('Total Units')}</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{payoutData.totalUnits}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.payoutPeriod').d('Payout Period')}</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={styles.paddingBtm}>
                {intl.get(`payout.months.${payoutData.monthString}`).d(payoutData.monthString)} {payoutData.year}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalPayoutAmount').d('Total Payout Amount')}</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={styles.paddingBtm}>{payoutData.totalPayoutAmount}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.normalText}>
        <View style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>
              {intl.get('payout.pdfPayout.propertyName').d('Property Name')}: {payoutData.propertyName}
            </Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.currentCollection').d('Current Collection to Date')}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>
              {intl.get('payout.pdfPayout.unitName').d('Unit Name')}: {payoutData.unitName}
            </Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>
              {getCurrency(payoutDetail)} {payoutData.currentCollectionToDate}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.paddingBtm}>Host Protect: {payoutData.certNo}</Text>
          </View>
        </View>
      </View>
    </View>
  );

  let expensesTotal = 0;
  const expensesDetails = payoutData.expenses.map((expense, i) => {
    expensesTotal = expense.amount + expensesTotal;
    const sharingRules = expense.sharingRules && '(' + expense.sharingRules.charAt(0).toUpperCase() + expense.sharingRules.slice(1) + ')';
    return (
      <View key={i}>
        <View key={`expense${i}`} style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>
              {intl.get(`expenses.utilityType.${expense.name}`).d(expense.name)}{' '}
              {intl.get(`servicePackage.expensesPeople.${sharingRules}`).d(sharingRules)}
            </Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>
              {expense.name !== 'Others' ? `(${getCurrency(payoutDetail)} ${numberWithCommas(expense.amount)})` : ''}
            </Text>
          </View>
        </View>
        {expense.name === 'Others' &&
          payoutData.otherExpensesData.map((otherExpenseData, j) => {
            return (
              <View key={`other${j}`} style={styles.row}>
                <View style={{ width: '60%' }}>
                  <Text style={styles.paddingBtm}>- {otherExpenseData.label}</Text>
                </View>
                <View style={{ width: '40%', textAlign: 'right' }}>
                  <Text style={styles.paddingBtm}>
                    ({getCurrency(payoutDetail)} {numberWithCommas(otherExpenseData.amount)})
                  </Text>
                </View>
              </View>
            );
          })}
      </View>
    );
  });

  const reservationDetails = payoutData.reservations.map((reservation, i) => {
    return (
      <View key={`reservation${i}`}>
        <View style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>
              {reservation.startDate} - {reservation.endDate} | {reservation.code}
            </Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>
              {getCurrency(payoutDetail)} {numberWithCommas(reservation.charges.total)}
            </Text>
          </View>
        </View>
        <View style={{ height: 5 }} />
      </View>
    );
  });

  const detailedReservationBreakdown = payoutData.reservations.map((reservation, i) => {
    const selectedIntegrationSource = Object.values(integrationSourceConstants).filter(
      integrationSource => reservation.platform === integrationSource.code
    );
    let otaFee;
    if (reservation.platform === 'hostplatform') {
      otaFee = 'N/A';
    } else {
      if (reservation.platform === 'others' && !reservation.priceDetails.platformFee) {
        otaFee = 'N/A';
      } else {
        otaFee = `${getCurrency(payoutDetail)} ${numberWithCommas(reservation.priceDetails.platformFee)}`;
      }
    }
    return (
      <View key={`reservationBreakdown${i}`}>
        <View style={styles.normalText}>
          <View style={styles.row}>
            <Text style={{ paddingTop: 6.5, width: '15%' }}>
              {reservation.startDate} - {reservation.endDate}
            </Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>{reservation.code}</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>
              {selectedIntegrationSource && selectedIntegrationSource[0] ? selectedIntegrationSource[0].label : '-'}
            </Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>{otaFee}</Text>
            <View style={{ paddingTop: 6.5, width: '28%' }}>
              <Text>
                {intl.get('payout.pdfPayout.roomRate').d('Room Rate')}: {getCurrency(payoutDetail)} {numberWithCommas(reservation.charges.rental)},
              </Text>
              <Text>
                {intl.get('payout.pdfPayout.cleaningPaid').d('Cleaning Paid By Guest')}: {getCurrency(payoutDetail)}{' '}
                {numberWithCommas(reservation.charges.cleaning)},
              </Text>
              <Text>
                {intl.get('payout.pdfPayout.extraGuest').d('Extra Guest Fee')}: {getCurrency(payoutDetail)}{' '}
                {numberWithCommas(reservation.charges.extraGuest)},
              </Text>
              <Text>
                {intl.get('payout.pdfPayout.otherShare').d('Other Share Charges')}: {getCurrency(payoutDetail)}{' '}
                {numberWithCommas(calculateOtherCharges(reservation.charges))}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.heightOfTen} />
      </View>
    );
  });

  const noReservation = (
    <View style={{ height: 50 }}>
      <Text style={styles.noReservationText}>{intl.get('payout.pdfPayout.noReservation').d('No Reservation')}</Text>
    </View>
  );

  const footer = (
    <View
      style={{
        ...styles.normalText,
        ...styles.footer
      }}
    >
      <Text>Powered by HostPlatform</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" wrap={true} style={styles.page}>
        <View>
          {headerDetails}
          <View style={styles.greyBlockText}>
            <Text style={{ paddingTop: 6.5 }}>{intl.get('payout.pdfPayout.servicePackage').d('Service Package')}</Text>
          </View>
          <View style={styles.heightOfTen} />
          <View style={styles.normalText}>
            <Text style={styles.paddingBtm}>
              {intl.get('payout.pdfPayout.servicePackageName').d('Service Package Name')} : {payoutData.servicePackageName}
            </Text>
            <Text style={styles.paddingBtm}>
              {intl.get('payout.pdfPayout.totalUnitsServicePackage').d('Total Units in Service Package')} : {payoutData.servicePackageUnits}
            </Text>
            <Text style={styles.paddingBtm}>
              {intl.get('payout.pdfPayout.servicePackageType').d('Service Package Type')} :{' '}
              {intl.get(`servicePackage.packageType.${payoutData.servicePackageTypeLabel}`).d(payoutData.servicePackageTypeLabel)} |{' '}
              {intl.get(`servicePackage.revenueShare.${payoutData.revenueShareLabel}`).d(payoutData.revenueShareLabel)} |{' '}
              {payoutData.servicePackageAmount}
            </Text>
          </View>
          <View style={styles.greyBlockText}>
            <Text style={{ paddingTop: 6.5 }}>{intl.get('payout.pdfPayout.reservationRevenue').d('Reservation Revenue Summary')}</Text>
          </View>
          <View style={styles.heightOfTen} />
          <View style={styles.normalText}>
            {reservationDetails.length === 0 ? noReservation : reservationDetails}
            <View style={styles.borderBottom} />
            <View style={styles.heightOfTen} />
            <View style={styles.row}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalReservationRevenue').d('Total Reservation Revenue')}</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>
                  {getCurrency(payoutDetail)} {numberWithCommas(payoutData.totalRevenue)}
                </Text>
              </View>
            </View>
          </View>
          {reservationDetails.length <= 5 && (
            <View>
              <View style={styles.heightOfTen} />
              <View style={styles.greyBlockText}>
                <Text style={{ paddingTop: 6.5 }}>{intl.get('payout.pdfPayout.totalExpensesPeriod').d('Total Expenses this Period')}</Text>
              </View>
              <View style={styles.heightOfTen} />
              <View style={styles.normalText}>
                {expensesDetails}
                <View style={styles.borderBottom} />
              </View>
              <View style={styles.heightOfTen} />
              <View style={{ ...styles.row, ...styles.normalText }}>
                <View style={{ width: '60%' }}>
                  <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalExpenses').d('Total Expenses')}</Text>
                </View>
                <View style={{ width: '40%', textAlign: 'right' }}>
                  <Text style={styles.paddingBtm}>
                    ({getCurrency(payoutDetail)} {numberWithCommas(expensesTotal)})
                  </Text>
                </View>
              </View>
              <View style={styles.heightOfTen} />
              <View style={styles.greyBlockText}>
                <Text style={{ paddingTop: 6.5 }}>{intl.get('payout.pdfPayout.totalProfit').d('Total Profit')}</Text>
              </View>
              <View style={styles.heightOfTen} />
              {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? (
                <View style={styles.normalText}>
                  <View style={styles.row}>
                    <View style={{ width: '60%' }}>
                      <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalNetProfit').d('Total Net Profit')}</Text>
                    </View>
                    <View style={{ width: '40%', textAlign: 'right' }}>
                      <Text style={styles.paddingBtm}>
                        {getCurrency(payoutDetail)} {payoutData.totalNetProfit}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <View style={styles.normalText}>
                  <View style={styles.row}>
                    <View style={{ width: '60%' }}>
                      <Text style={styles.paddingBtm}>
                        {intl.get('payout.pdfPayout.ownerRevShare').d('Owner Revenue Share')} {payoutData.ownersRevenueSharePercentage}
                      </Text>
                    </View>
                    <View style={{ width: '40%', textAlign: 'right' }}>
                      <Text style={styles.paddingBtm}>
                        {getCurrency(payoutDetail)} {payoutData.ownersRevenueShare}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '60%' }}>
                      <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.ownerTotalBorneExpenses').d('Owner Total Borne Expenses')}</Text>
                    </View>
                    <View style={{ width: '40%', textAlign: 'right' }}>
                      <Text style={styles.paddingBtm}>
                        ({getCurrency(payoutDetail)} {payoutData.totalOwnerBorneExpenses})
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={styles.heightOfTen} />
              <View style={styles.blueBlockText}>
                <View style={{ ...styles.row, paddingTop: 6.5 }}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>
                      {intl.get('payout.pdfPayout.ownerNetProfit').d('Owner Net Profit')}{' '}
                      {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) &&
                        payoutData.ownersRevenueSharePercentage}
                    </Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      {getCurrency(payoutDetail)} {payoutData.nettProfit}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {footer}
        </View>
      </Page>
      {reservationDetails.length > 5 && (
        <Page size="A4" wrap={true} style={styles.page}>
          <View>
            <View style={styles.heightOfTen} />
            <View style={styles.greyBlockText}>
              <Text style={{ paddingTop: 6.5 }}>{intl.get('payout.pdfPayout.totalExpensesPeriod').d('Total Expenses this Period')}</Text>
            </View>
            <View style={styles.heightOfTen} />
            <View style={styles.normalText}>
              {expensesDetails}
              <View style={styles.borderBottom} />
            </View>
            <View style={styles.heightOfTen} />
            <View style={{ ...styles.row, ...styles.normalText }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalExpenses').d('Total Expenses')}</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>
                  ({getCurrency(payoutDetail)} {numberWithCommas(expensesTotal)})
                </Text>
              </View>
            </View>
            <View style={styles.heightOfTen} />
            <View style={styles.greyBlockText}>
              <Text style={{ paddingTop: 6.5 }}>{intl.get('payout.pdfPayout.totalProfit').d('Total Profit')}</Text>
            </View>
            <View style={styles.heightOfTen} />
            {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? (
              <View style={styles.normalText}>
                <View style={styles.row}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalNetProfit').d('Total Net Profit')}</Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      {getCurrency(payoutDetail)} {payoutData.totalNetProfit}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.normalText}>
                <View style={styles.row}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>
                      {intl.get('payout.pdfPayout.ownerRevShare').d('Owner Revenue Share')} {payoutData.ownersRevenueSharePercentage}
                    </Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      {' '}
                      {getCurrency(payoutDetail)} {payoutData.ownersRevenueShare}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.ownerTotalBorneExpenses').d('Owner Total Borne Expenses')}</Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      ({getCurrency(payoutDetail)} {payoutData.totalOwnerBorneExpenses})
                    </Text>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.heightOfTen} />
            <View style={styles.blueBlockText}>
              <View style={{ ...styles.row, paddingTop: 6.5 }}>
                <View style={{ width: '60%' }}>
                  <Text style={styles.paddingBtm}>
                    {intl.get('payout.pdfPayout.ownerNetProfit').d('Owner Net Profit')}{' '}
                    {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) &&
                      payoutData.ownersRevenueSharePercentage}
                  </Text>
                </View>
                <View style={{ width: '40%', textAlign: 'right' }}>
                  <Text style={styles.paddingBtm}>
                    {getCurrency(payoutDetail)} {payoutData.nettProfit}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {footer}
        </Page>
      )}
      <Page size="A4" wrap={true} style={styles.page}>
        <View>
          <View>{headerDetails}</View>
          <View style={styles.title}>
            <Text>{intl.get('payout.pdfPayout.detailedReservation').d('Detailed Reservation Breakdown')}</Text>
          </View>
          <View style={styles.heightOfTen} />
          <View style={{ ...styles.greyBlockText, ...styles.row }}>
            <Text style={{ paddingTop: 6.5, width: '15%' }}>{intl.get('payout.pdfPayout.date').d('Date')}</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>
              {intl.get('payout.pdfPayout.confirmationCode').d('Confirmation Code')}
            </Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>{intl.get('payout.pdfPayout.source').d('Source')}</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>{intl.get('payout.pdfPayout.otaFee').d('OTA Fee')}</Text>
            <Text style={{ paddingTop: 6.5, width: '28%' }}>{intl.get('payout.pdfPayout.revenueBreakdown').d('Revenue Breakdown')}</Text>
          </View>
          <View style={styles.heightOfTen} />
          {detailedReservationBreakdown.length === 0 ? noReservation : detailedReservationBreakdown}
          <View style={styles.blueBlockText}>
            <View style={{ ...styles.row, paddingTop: 6.5 }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalReservationRevenue').d('Total Reservation Revenue')}</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>
                  {getCurrency(payoutDetail)} {numberWithCommas(payoutData.totalRevenue)}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ height: 20 }} />
          <View style={styles.blueBlockText}>
            <View style={{ ...styles.row, paddingTop: 6.5 }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>{intl.get('payout.pdfPayout.totalReservation').d('Total Number of Reservations')}</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>{payoutData.numberofReservations}</Text>
              </View>
            </View>
          </View>
        </View>
        {footer}
      </Page>
    </Document>
  );
};

const handleOnClickInvoicePayout = (
  integrationSourceConstants,
  packageTypeLabel,
  revenueShareLabel,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges,
  setIsInvoicePayoutClicked,
  setInvoiceData
) => {
  setIsInvoicePayoutClicked(true);

  const invoiceData = generatePayoutDocument(
    integrationSourceConstants,
    packageTypeLabel,
    revenueShareLabel,
    selectedHostImage,
    selectedOwnerName,
    payoutDetail,
    calculateOtherCharges
  );

  setInvoiceData(invoiceData);
};

const generatePDF = (
  integrationSourceConstants,
  packageTypeLabel,
  revenueShareLabel,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges,
  setIsInvoicePayoutClickedJSPDF,
  currentLocale
) => {
  setIsInvoicePayoutClickedJSPDF(true);

  const getCurrency = payoutDetailInfo => guard(() => payoutDetailInfo.unit.currency, 'RM');
  const payoutData = {
    ...constructDataForPayoutTemplatePdf(packageTypeLabel, revenueShareLabel, selectedHostImage, selectedOwnerName, payoutDetail)
  };

  // Create a new jsPDF instance
  const doc = new jsPDF();
  let paddingBottom = 1;
  let headerSpacing;
  let rightSpacing;
  let breakDownFontSize;
  let breakdownRightSpacing;
  if (currentLocale === 'zh-CN') {
    doc.setFont('NotoSansSC-Regular');
    headerSpacing = 70;
    rightSpacing = 180;
    breakDownFontSize = 10;
    breakdownRightSpacing = 155;
  } else {
    doc.setFont('NotoSansThai-Regular');
    headerSpacing = 95;
    rightSpacing = 160;
    breakDownFontSize = 9;
    breakdownRightSpacing = 150;
  }

  const imgWidth = 40;
  const imgHeight = 40;

  // Add black border
  doc.setDrawColor(0);
  doc.setLineWidth(0.5);
  doc.rect(5, 5, imgWidth, imgHeight);
  doc.addImage(selectedHostImage, 'JPEG', 5, 5, imgWidth, imgHeight);

  //Footer
  const footer = doc => {
    doc.setTextColor('#E7E5E6');
    doc.setFontSize(8);
    doc.text('Powered by Host Platform', 85, 290);
    doc.setFontSize(10);
    doc.setTextColor('#000000');
  };

  // Add content to the PDF
  doc.setFontSize(10);
  const headerText = [
    { text: `${intl.get('payout.pdfPayout.ownerName').d('Owner Name')} `, x: 50, y: 25 + paddingBottom },
    { text: ` : ${selectedOwnerName}`, x: headerSpacing, y: 25 + paddingBottom },
    { text: `${intl.get('payout.pdfPayout.totalUnit').d('Total Units')} `, x: 50, y: 30 + paddingBottom },
    { text: ` : ${payoutDetail.totalUnits}`, x: headerSpacing, y: 30 + paddingBottom },
    {
      text: `${intl.get('payout.pdfPayout.payoutPeriod').d('Payout Period')} `,
      x: 50,
      y: 35 + paddingBottom
    },
    {
      text: ` : ${intl.get(`payout.months.${payoutData.monthString}`).d(payoutData.monthString)} ${payoutData.year}`,
      x: headerSpacing,
      y: 35 + paddingBottom
    },
    {
      text: `${intl.get('payout.pdfPayout.totalPayoutAmount').d('Total Payout Amount')} `,
      x: 50,
      y: 40 + paddingBottom
    },
    {
      text: ` : ${payoutData.totalPayoutAmount}`,
      x: headerSpacing,
      y: 40 + paddingBottom
    },
    { text: `${intl.get('payout.pdfPayout.propertyName').d('Property Name')} : ${payoutData.propertyName}`, x: 5, y: 55 + paddingBottom },
    { text: `${intl.get('payout.pdfPayout.currentCollection').d('Current Collection to Date')}`, x: rightSpacing, y: 55 + paddingBottom },
    { text: `${intl.get('payout.pdfPayout.unitName').d('Unit Name')} : ${payoutData.unitName}`, x: 5, y: 60 + paddingBottom },
    { text: `${getCurrency(payoutDetail)} ${payoutData.currentCollectionToDate}`, x: rightSpacing, y: 60 + paddingBottom },
    { text: `Host Protect : ${payoutData.certNo}`, x: 5, y: 65 + paddingBottom }
  ];

  doc.setFillColor(231, 229, 230); // Light grey color
  doc.rect(4, 70, 202, 8, 'F');

  const servicePackcage = [
    {
      text: `${intl.get('payout.pdfPayout.servicePackage').d('Service Package')}`,
      x: 5,
      y: 75
    },
    {
      text: `${intl.get('payout.pdfPayout.servicePackageName').d('Service Package Name')} : ${payoutData.servicePackageName}`,
      x: 5,
      y: 82 + paddingBottom
    },
    {
      text: `${intl.get('payout.pdfPayout.totalUnitsServicePackage').d('Total Units in Service Package')} : ${payoutData.servicePackageUnits}`,
      x: 5,
      y: 87 + paddingBottom
    },
    {
      text: `${intl.get('payout.pdfPayout.servicePackageType').d('Service Package Type')} : ${intl
        .get(`servicePackage.packageType.${payoutData.servicePackageTypeLabel}`)
        .d(payoutData.servicePackageTypeLabel)} | ${intl
        .get(`servicePackage.revenueShare.${payoutData.revenueShareLabel}`)
        .d(payoutData.revenueShareLabel)} | ${payoutData.servicePackageAmount}
    `,
      x: 5,
      y: 92 + paddingBottom
    }
  ];

  doc.setFillColor(231, 229, 230); // Light grey color
  doc.rect(4, 95, 202, 8, 'F');

  let gap = 0;
  const reservationDetails = payoutData.reservations.map((reservation, i) => {
    const reservationText = {
      text: `${reservation.startDate} - ${reservation.endDate} | ${reservation.code}`,
      x: 5,
      y: 108 + gap
    };
    const chargesText = {
      text: `${getCurrency(payoutDetail)} ${numberWithCommas(reservation.charges.total)}`,
      x: 180,
      y: 108 + gap
    };
    gap += 7;
    return [reservationText, chargesText];
  });

  const noReservation = {
    text: `${intl.get('payout.pdfPayout.noReservation').d('No Reservation')}`,
    x: 90,
    y: 108
  };

  if (reservationDetails.length === 0) {
    gap += 7;
  }

  let totalHeightReservation = 108 + gap;
  const ReservationRevenueSummary = [
    {
      text: `${intl.get('payout.pdfPayout.reservationRevenue').d('Reservation Revenue Summary')}`,
      x: 5,
      y: 100
    },
    ...(reservationDetails.length > 0 ? reservationDetails.flat() : [noReservation]),
    { type: 'line', x1: 5, y1: totalHeightReservation, x2: 200, y2: totalHeightReservation },
    {
      text: `${intl.get('payout.pdfPayout.totalReservationRevenue').d('Total Reservation Revenue')}`,
      x: 5,
      y: totalHeightReservation + 5
    },
    {
      text: `${getCurrency(payoutDetail)} ${numberWithCommas(payoutData.totalRevenue)}`,
      x: 180,
      y: totalHeightReservation + 5
    }
  ];

  let addPageTriggerReservation = false;
  if (payoutData.reservations.length > 8) {
    addPageTriggerReservation = true;
    totalHeightReservation = 0;
  }

  let startingPointOfTotalExpense = totalHeightReservation + 15;
  let expensesTotal = 0;
  let expensesGap = 0;
  let totalExpensesGap = 20;
  const expensesDetails = payoutData.expenses.map((expense, i) => {
    expensesTotal = expense.amount + expensesTotal;
    const sharingRules = expense.sharingRules && '(' + expense.sharingRules.charAt(0).toUpperCase() + expense.sharingRules.slice(1) + ')';
    const result = [
      {
        text: `${intl.get(`expenses.utilityType.${expense.name}`).d(expense.name)} ${intl
          .get(`servicePackage.expensesPeople.${sharingRules}`)
          .d(sharingRules)}`,
        x: 5,
        y: startingPointOfTotalExpense + 15 + expensesGap
      },
      {
        text: expense.name !== 'Others' ? `(${getCurrency(payoutDetail)} ${numberWithCommas(expense.amount)})` : '',
        x: 180,
        y: startingPointOfTotalExpense + 15 + expensesGap
      }
    ];
    expensesGap += 5; // Increment expensesGap
    return result;
  });

  const expensesDetailsOthers = payoutData.expenses.map((expense, i) => {
    if (expense.name === 'Others') {
      let otherExpensesData = [];
      payoutData.otherExpensesData.forEach((otherExpenseData, j) => {
        otherExpensesData.push({
          text: `- ${otherExpenseData.label}`,
          x: 5,
          y: startingPointOfTotalExpense + 15 + expensesGap
        });
        otherExpensesData.push({
          text: `(${getCurrency(payoutDetail)} ${numberWithCommas(otherExpenseData.amount)})`,
          x: 180,
          y: startingPointOfTotalExpense + 15 + expensesGap
        });
        expensesGap += 5; // Increment expensesGap
      });
      return otherExpensesData;
    }
    return [];
  });

  const totalExpenses = [
    {
      text: `${intl.get('payout.pdfPayout.totalExpensesPeriod').d('Total Expenses this Period')}`,
      x: 5,
      y: startingPointOfTotalExpense + 5
    },
    ...expensesDetails.flat(),
    ...expensesDetailsOthers.flat(),
    { type: 'line', x1: 5, y1: startingPointOfTotalExpense + 15 + expensesGap, x2: 200, y2: startingPointOfTotalExpense + 15 + expensesGap },
    {
      text: `${intl.get('payout.pdfPayout.totalExpenses').d('Total Expenses')}`,
      x: 5,
      y: startingPointOfTotalExpense + totalExpensesGap + expensesGap
    },
    {
      text: `(${getCurrency(payoutDetail)} ${numberWithCommas(expensesTotal)})`,
      x: 180,
      y: startingPointOfTotalExpense + totalExpensesGap + expensesGap
    }
  ];

  let startingPointOfProfit = startingPointOfTotalExpense + expensesGap + totalExpensesGap + 15;
  let profitGap = 0;

  const totalProfitCalculations = payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4)
    ? [
        {
          text: `${intl.get('payout.pdfPayout.totalNetProfit').d('Total Net Profit')} ${getCurrency(payoutDetail)} ${payoutData.totalNetProfit}`,
          x: 5,
          y: startingPointOfProfit + 15
        },
        {
          text: `${getCurrency(payoutDetail)} ${payoutData.totalNetProfit}`,
          x: 180,
          y: startingPointOfProfit + 15
        }
      ]
    : [
        {
          text: `${intl.get('payout.pdfPayout.ownerRevShare').d('Owner Revenue Share')} ${payoutData.ownersRevenueSharePercentage}`,
          x: 5,
          y: startingPointOfProfit + 15
        },
        {
          text: `${getCurrency(payoutDetail)} ${payoutData.ownersRevenueShare}`,
          x: 180,
          y: startingPointOfProfit + 15
        },
        {
          text: `${intl.get('payout.pdfPayout.ownerTotalBorneExpenses').d('Owner Total Borne Expenses')}`,
          x: 5,
          y: startingPointOfProfit + 20
        },
        {
          text: `(${getCurrency(payoutDetail)} ${payoutData.totalOwnerBorneExpenses})`,
          x: 180,
          y: startingPointOfProfit + 20
        }
      ];

  const totalProfit = [
    {
      text: `${intl.get('payout.pdfPayout.totalProfit').d('Total Profit')}`,
      x: 5,
      y: startingPointOfProfit + 5
    },
    ...totalProfitCalculations
  ];

  let startingOwnerNetProfit = startingPointOfProfit + 25;

  const ownerNetProfit = [
    {
      text: `${intl.get('payout.pdfPayout.ownerNetProfit').d('Owner Net Profit')} ${
        payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? payoutData.ownersRevenueSharePercentage : ''
      }`,
      x: 5,
      y: startingOwnerNetProfit + 5
    },
    {
      text: `${getCurrency(payoutDetail)} ${payoutData.nettProfit}`,
      x: 180,
      y: startingOwnerNetProfit + 5
    }
  ];

  headerText.forEach(({ text, x, y }) => {
    doc.text(text, x, y);
  });

  servicePackcage.forEach(({ text, x, y }) => {
    doc.text(text, x, y);
  });

  ReservationRevenueSummary.forEach(item => {
    if (item.type === 'line') {
      doc.line(item.x1, item.y1, item.x2, item.y2);
    } else {
      doc.text(item.text, item.x, item.y);
    }
  });

  if (addPageTriggerReservation) {
    footer(doc);
    doc.addPage();
  }

  doc.setFillColor(231, 229, 230); // Light grey color
  doc.rect(4, startingPointOfTotalExpense, 202, 8, 'F');

  doc.setFillColor(231, 229, 230); // Light grey color
  doc.rect(4, startingPointOfProfit, 202, 8, 'F');

  doc.setFillColor(186, 227, 236); // Light blue color
  doc.rect(4, startingOwnerNetProfit, 202, 8, 'F');

  totalExpenses.forEach(item => {
    if (item.type === 'line') {
      doc.line(item.x1, item.y1, item.x2, item.y2);
    } else {
      doc.text(item.text, item.x, item.y);
    }
  });

  totalProfit.forEach(item => {
    if (item.type === 'line') {
      doc.line(item.x1, item.y1, item.x2, item.y2);
    } else {
      doc.text(item.text, item.x, item.y);
    }
  });

  ownerNetProfit.forEach(item => {
    if (item.type === 'line') {
      doc.line(item.x1, item.y1, item.x2, item.y2);
    } else {
      doc.text(item.text, item.x, item.y);
    }
  });

  footer(doc);

  // ------------------------- Second Page --------------------------- //

  doc.addPage();

  doc.setDrawColor(0);
  doc.setLineWidth(0.5);
  doc.rect(5, 5, imgWidth, imgHeight);
  doc.addImage(selectedHostImage, 'JPEG', 5, 5, imgWidth, imgHeight);

  //DetailedReservationBreakdown
  let startingOfBreakdown = 75;

  doc.setFillColor(231, 229, 230);
  doc.rect(4, startingOfBreakdown + 3, 202, 8, 'F');

  const breakdownHeader = [
    {
      text: `${intl.get('payout.pdfPayout.detailedReservation').d('Detailed Reservation Breakdown')}`,
      x: 60,
      y: startingOfBreakdown
    }
  ];

  let startingofBreakdownDetails = 75 + 13 + 5;
  let breakdownGap = 0;

  let pageBreakCounter = 0;
  let isFirstPage = true;

  const detailedReservationBreakdown = payoutData.reservations.map((reservation, i) => {
    const selectedIntegrationSource = Object.values(integrationSourceConstants).filter(
      integrationSource => reservation.platform === integrationSource.code
    );
    let otaFee;
    if (reservation.platform === 'hostplatform') {
      otaFee = 'N/A';
    } else {
      if (reservation.platform === 'others' && !reservation.priceDetails.platformFee) {
        otaFee = 'N/A';
      } else {
        otaFee = `${getCurrency(payoutDetail)} ${numberWithCommas(reservation.priceDetails.platformFee)}`;
      }
    }
    const result = [
      {
        text: `${reservation.startDate} -`,
        x: 5,
        y: startingofBreakdownDetails + breakdownGap
      },
      {
        text: `${reservation.endDate}`,
        x: 5,
        y: startingofBreakdownDetails + breakdownGap + 5
      },
      {
        text: reservation.code,
        x: 45,
        y: startingofBreakdownDetails + breakdownGap
      },
      {
        text: selectedIntegrationSource && selectedIntegrationSource[0] ? selectedIntegrationSource[0].label : '-',
        x: 85,
        y: startingofBreakdownDetails + breakdownGap
      },
      {
        text: otaFee,
        x: 125,
        y: startingofBreakdownDetails + breakdownGap
      },
      {
        text: `${intl.get('payout.pdfPayout.roomRate').d('Room Rate')}: ${getCurrency(payoutDetail)} ${numberWithCommas(reservation.charges.rental)}`,
        x: breakdownRightSpacing,
        y: startingofBreakdownDetails + breakdownGap
      },
      {
        text: `${intl.get('payout.pdfPayout.cleaningPaid').d('Cleaning Paid By Guest')}: ${getCurrency(payoutDetail)} ${numberWithCommas(
          reservation.charges.cleaning
        )}`,
        x: breakdownRightSpacing,
        y: startingofBreakdownDetails + 5 + breakdownGap
      },
      {
        text: `${intl.get('payout.pdfPayout.extraGuest').d('Extra Guest Fee')}: ${getCurrency(payoutDetail)} ${numberWithCommas(
          reservation.charges.extraGuest
        )}`,
        x: breakdownRightSpacing,
        y: startingofBreakdownDetails + 10 + breakdownGap
      },
      {
        text: `${intl.get('payout.pdfPayout.otherShare').d('Other Share Charges')}: ${getCurrency(payoutDetail)} ${numberWithCommas(
          calculateOtherCharges(reservation.charges)
        )}`,
        x: breakdownRightSpacing,
        y: startingofBreakdownDetails + 15 + breakdownGap
      }
    ];

    breakdownGap += 25; // Increment breakdownGap by 20
    pageBreakCounter++;
    if (!isFirstPage && pageBreakCounter === 9 && i !== payoutData.reservations.length - 1) {
      result.push({ pageBreak: 'after' }); // New page
      startingofBreakdownDetails = 25;
      breakdownGap = 0;
      pageBreakCounter = 0; // Reset counter for the new page
    }

    if (isFirstPage && pageBreakCounter === 6 && i !== payoutData.reservations.length - 1) {
      result.push({ pageBreak: 'after' });
      startingofBreakdownDetails = 25;
      breakdownGap = 0;
      pageBreakCounter = 0; // Reset counter for the first page
      isFirstPage = false; // Set flag to false after the first page
    }

    return result;
  });

  const noDetailedReservationBreakdown = {
    text: `${intl.get('payout.pdfPayout.noReservation').d('No Reservation')}`,
    x: 90,
    y: startingofBreakdownDetails
  };

  const breakdownDetails = [
    {
      text: `${intl.get('payout.pdfPayout.date').d('Date')}`,
      x: 5,
      y: startingOfBreakdown + 8
    },
    {
      text: `${intl.get('payout.pdfPayout.confirmationCode').d('Confirmation Code')}`,
      x: 45,
      y: startingOfBreakdown + 8
    },
    {
      text: `${intl.get('payout.pdfPayout.source').d('Source')}`,
      x: 85,
      y: startingOfBreakdown + 8
    },
    {
      text: `${intl.get('payout.pdfPayout.otaFee').d('OTA Fee')}`,
      x: 125,
      y: startingOfBreakdown + 8
    },
    {
      text: `${intl.get('payout.pdfPayout.revenueBreakdown').d('Revenue Breakdown')}`,
      x: 165,
      y: startingOfBreakdown + 8
    },
    ...(detailedReservationBreakdown.length === 0 ? [noDetailedReservationBreakdown] : detailedReservationBreakdown.flat())
  ];

  let startingTotalReservationRevenue = startingofBreakdownDetails + breakdownGap + 5;

  const totalReservationRevenue = [
    {
      text: `${intl.get('payout.pdfPayout.totalReservationRevenue').d('Total Reservation Revenue')}`,
      x: 5,
      y: startingTotalReservationRevenue + 5
    },
    {
      text: `${getCurrency(payoutDetail)} ${numberWithCommas(payoutData.totalRevenue)}`,
      x: 180,
      y: startingTotalReservationRevenue + 5
    }
  ];

  let startingTotalReservation = startingTotalReservationRevenue + 15;

  const totalReservation = [
    {
      text: `${intl.get('payout.pdfPayout.totalReservation').d('Total Number of Reservations')}`,
      x: 5,
      y: startingTotalReservation + 5
    },
    {
      text: `${payoutData.numberofReservations}`,
      x: 180,
      y: startingTotalReservation + 5
    }
  ];

  headerText.forEach(({ text, x, y }) => {
    doc.text(text, x, y);
  });

  doc.setFontSize(25);
  breakdownHeader.forEach(({ text, x, y }) => {
    doc.text(text, x, y);
  });
  doc.setFontSize(breakDownFontSize);
  breakdownDetails.forEach(({ text, x, y, pageBreak }) => {
    if (pageBreak === 'after') {
      footer(doc);
      doc.addPage();
      doc.setFontSize(breakDownFontSize);
    } else {
      doc.text(text, x, y);
    }
  });
  doc.setFillColor(186, 227, 236);
  doc.rect(4, startingTotalReservationRevenue, 202, 8, 'F');

  doc.setFillColor(186, 227, 236);
  doc.rect(4, startingTotalReservation, 202, 8, 'F');

  doc.setFontSize(10);
  totalReservationRevenue.forEach(({ text, x, y }) => {
    doc.text(text, x, y);
  });
  totalReservation.forEach(({ text, x, y }) => {
    doc.text(text, x, y);
  });
  footer(doc);

  // Generate a Blob from the PDF
  const pdfBlob = doc.output('blob');
  // Create a URL for the Blob
  const pdfURL = URL.createObjectURL(pdfBlob);
  // Open the PDF in a new tab
  window.open(pdfURL);
  setIsInvoicePayoutClickedJSPDF(false);
};

const PayoutInvoice = ({
  integrationSourceConstants,
  packageTypeConstants,
  revenueShareConstants,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges
}) => {
  const { currentLocale } = useContext(LangContext);
  const [invoiceData, setInvoiceData] = useState(DEFAULT_DOCUMENT_DOM);
  const [isInvoicePayoutClicked, setIsInvoicePayoutClicked] = useState(false);
  const [isInvoicePayoutClickedJSPDF, setIsInvoicePayoutClickedJSPDF] = useState(false);

  const { packageTypeLabel, revenueShareLabel } = useFetchLabels(packageTypeConstants, revenueShareConstants, payoutDetail);

  // Store blob data in refs to avoid re-renders
  const blobDataRef = useRef({ blob: null, url: null });

  const handleBlobData = useCallback(
    ({ blob, url }) => {
      blobDataRef.current = { blob, url };
      const isDataLoaded = !!blob && blob.size > DEFAULT_DOCUMENT_SIZE;

      if (isInvoicePayoutClicked && isDataLoaded) {
        window.open(url, '_blank');
        setIsInvoicePayoutClicked(false);
      }
    },
    [isInvoicePayoutClicked]
  );

  // Reset the blobDataRef once everything is done
  useEffect(() => {
    if (!isInvoicePayoutClicked) {
      blobDataRef.current = { blob: null, url: null }; // Reset blobDataRef
    }
  }, [isInvoicePayoutClicked]); // Dependency on `isInvoicePayoutClicked` to reset when done

  if (currentLocale !== 'en-US') {
    return isInvoicePayoutClickedJSPDF ? (
      <div>
        <Button type="primary" disabled icon="loading">
          {intl.get('payout.headerLabels.loading').d('Loading..')}
        </Button>
      </div>
    ) : (
      <div>
        <Button
          id="inv-button1-payout"
          type="primary"
          onClick={() =>
            generatePDF(
              integrationSourceConstants,
              packageTypeLabel,
              revenueShareLabel,
              selectedHostImage,
              selectedOwnerName,
              payoutDetail,
              calculateOtherCharges,
              setIsInvoicePayoutClickedJSPDF,
              currentLocale
            )
          }
        >
          {intl.get('payout.headerLabels.invoicePayout').d('Invoice Payout')}
        </Button>
      </div>
    );
  }

  return (
    <BlobProvider document={invoiceData} fileName="Invoice-Payout.pdf">
      {blobData => {
        // Check if blobData has changed and update blobDataRef
        if (blobData && blobDataRef.current) {
          handleBlobData(blobData);
        }

        return isInvoicePayoutClicked ? (
          <Button type="primary" disabled icon="loading">
            {intl.get('payout.headerLabels.loading').d('Loading..')}
          </Button>
        ) : (
          <Button
            id="inv-button1-payout"
            type="primary"
            onClick={() =>
              handleOnClickInvoicePayout(
                integrationSourceConstants,
                packageTypeLabel,
                revenueShareLabel,
                selectedHostImage,
                selectedOwnerName,
                payoutDetail,
                calculateOtherCharges,
                setIsInvoicePayoutClicked,
                setInvoiceData
              )
            }
          >
            {intl.get('payout.headerLabels.invoicePayout').d('Invoice Payout')}
          </Button>
        );
      }}
    </BlobProvider>
  );
};

PayoutInvoice.propTypes = {
  integrationSourceConstants: PropTypes.array.isRequired,
  packageTypeConstants: PropTypes.array.isRequired,
  revenueShareConstants: PropTypes.array.isRequired,
  selectedHostImage: PropTypes.string.isRequired,
  selectedOwnerName: PropTypes.string.isRequired,
  payoutDetail: PropTypes.object.isRequired,
  calculateOtherCharges: PropTypes.func.isRequired
};

export default PayoutInvoice;
