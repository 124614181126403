import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col } from 'antd';
import intl from 'react-intl-universal';

import FormInput from 'components/FormInput/FormInput';

import { checkValidStrongPassword } from 'utils/general';

const FormPassword = ({ form, isSideBySide, shouldHideLabel, isResetPassword, passwordClassName }) => {
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  const passwordString = isResetPassword ? 'new password' : 'password';

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setIsPasswordChange(isPasswordChange || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      return callback('Both password are not the same');
    }
    callback();
  };

  const validateToNextPassword = (rule, value, callback) => {
    const isValidPassword = checkValidStrongPassword(value);
    if (!isValidPassword) {
      return callback('Password should be 12-30 alphanumeric with at least 1 lowercase and 1 uppercase');
    } else if (value && isPasswordChange) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  return (
    <>
      <Row type="flex" style={{ width: '100%' }}>
        <Col span={24} className={passwordClassName}>
          <FormInput
            form={form}
            formLabel={!shouldHideLabel ? `${'Your'} ${passwordString}` : undefined}
            requiredErrorMessage={`Your ${passwordString} is required`}
            inputType="password"
            name="password"
            placeholder={`Type your ${passwordString} here`}
            extraProps={{ prefix: <Icon type="lock" /> }}
            extraRules={[{ validator: validateToNextPassword }]}
          />
        </Col>
        <Col span={24} className={passwordClassName}>
          <FormInput
            form={form}
            formLabel={!shouldHideLabel ? `Confirm your ${passwordString}` : undefined}
            requiredErrorMessage={`Please confirm your ${passwordString}`}
            inputType="password"
            name="confirmPassword"
            placeholder={`Retype your ${passwordString} here`}
            extraProps={{ prefix: <Icon type="lock" />, onBlur: handleConfirmBlur }}
            extraRules={[{ validator: compareToFirstPassword }]}
          />
        </Col>
      </Row>
    </>
  );
};

FormPassword.propTypes = {
  form: PropTypes.object.isRequired,
  isSideBySide: PropTypes.bool,
  shouldHideLabel: PropTypes.bool
};

FormPassword.defaultProps = {
  isSideBySide: false,
  shouldHideLabel: false
};

export default FormPassword;
