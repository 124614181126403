import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Form, Select, Modal, message, Tooltip, Tag, Icon, Input, Radio, Row, Col, Switch, Badge } from 'antd';
import moment from 'moment';
import {
  constructColumnFilterCheckbox,
  constructColumnFilterRadio,
  constructColumnFilterSearch,
  constructColumn,
  handleOnAggregationTableChange
} from 'utils/table/table';
import { useGetPaginatedWebhook, getWebhookByHost, updateWebhookConfigure, retryWebhook, testWebhook } from 'utils/apis/webhook';
import { getHosts } from '../../utils/apis/host';
import { StyledCard, StyledHeader } from './Webhook.styles';
import './Webhook.css';
import { withAppContext } from 'context/AppContext';
import { CSVLink } from 'react-csv';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const Webhook = props => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    // {
    //   createdAt: moment().subtract(987, 'second'),
    //   requestId: '5e9b1b1b1b1b1b1b1b1b1b1b',
    //   ipAddress: '192.168.1.254',
    //   url: 'https://api.example.com',
    //   host: 'HSR Hotel',
    //   status: 'Success'
    // },
    // {
    //   createdAt: moment().subtract(1234, 'second'),
    //   requestId: '2rfewf254refw14efdg',
    //   ipAddress: '172.169.1.140',
    //   url: 'https://api.example.com',
    //   status: 'Failed',
    //   host: 'Homolive Property',
    //   errorMsg: 'Server Error 500'
    // },
    // {
    //   createdAt: moment().subtract(3126, 'second'),
    //   requestId: 'gre9hjy99gndf-s-rew21',
    //   ipAddress: '176.1.0.65',
    //   url: 'https://api.example.com',
    //   host: 'Nya Motel',
    //   status: 'Success'
    // }
  ]);

  const [query, setQuery] = useState({ currentPage: 1, limit: 10 });
  const { isLoading: isGetUserLoading, paginatedData: userList, total, refetch } = useGetPaginatedWebhook(query);

  console.log('userList', userList);

  const [hostList, setHostList] = useState([]);

  const [configureData, setConfigureData] = useState({
    // url: 'https://api.example.com',
    // headerAuthentication: false,
    // host:"",
    // enabled: false,
    // authHeaderName: '',
    // authKey: '',
    // eventType: ['newReservation']
  });

  const [webhookVisible, setWebhookVisible] = useState(false);
  const [eventList, setEventList] = useState([
    {
      label: 'New Reservation',
      value: 'newReservation'
    },
    {
      label: 'Cancel Reservation',
      value: 'cancelReservation'
    },
    {
      label: 'Update Reservation (All)',
      value: 'updateReservation'
    },
    {
      label: 'Update Reservation (Contact Only)',
      value: 'contactOnly'
    },
    {
      label: 'Delete Reservation',
      value: 'deleteReservation'
    }
  ]);

  const [csv_data, set_csv_data] = useState([]);
  const [csv_header, set_csv_header] = useState([
    {
      label: 'Creation Date',
      key: 'createdAt'
    },
    {
      label: 'Host',
      key: 'host'
    },
    // {
    //   label: 'IP Address',
    //   key: 'ipAddress'
    // },
    {
      label: 'URL',
      key: 'url'
    },
    {
      label: 'Event',
      key: 'eventType'
    },
    {
      label: 'Attempt',
      key: 'attempt'
    },
    {
      label: 'Status',
      key: 'status'
    }
  ]);

  var allList = useGetPaginatedWebhook({
    limit: 0
  });

  useEffect(() => {
    console.log('webhook props', props);

    // if (props.checkIsAdmin()) {
    GetHost();
    // }

    return () => {};
  }, []);

  const GetWebhookByHost = async hostId => {
    setLoading(true);
    const res = await getWebhookByHost(hostId);
    console.log('res webhook host', res);
    setLoading(false);
    if (res.status !== 'error') {
      if (res.data.length === 0 || res.data.message) {
        props.form.resetFields();

        props.form.setFieldsValue({
          host: hostId
        });
        setConfigureData({
          host: hostId
        });
      }

      if (res.data.length > 0) {
        props.form.setFieldsValue({
          url: res.data[0].url,
          headerAuthentication: res.data[0].headerAuthentication,
          host: hostId,
          enabled: res.data[0].enabled,
          authHeaderName: res.data[0].authHeaderName,
          authKey: res.data[0].authKey,
          eventType: res.data[0].eventType
        });

        setConfigureData({
          url: res.data[0].url,
          headerAuthentication: res.data[0].headerAuthentication,
          host: hostId,
          enabled: res.data[0].enabled,
          authHeaderName: res.data[0].authHeaderName,
          authKey: res.data[0].authKey,
          eventType: res.data[0].eventType
        });
      }
    }
  };

  const GetHost = async () => {
    setLoading(true);
    const res = await getHosts();

    console.log('res', res);
    setLoading(false);

    if (res.status !== 'error') {
      if (res.data.length > 0) {
        GetWebhookByHost(res.data[0]._id);

        setHostList(
          res.data.map(host => ({
            value: host._id,
            label: host.name
          }))
        );
      }
    }
  };

  const RetryWebhook = async id => {
    setLoading(true);
    const res = await retryWebhook(id);
    console.log('res', res);
    setLoading(false);

    if (res.status >= 200 && res.status < 300) {
      message.success('Webhook Retry Successfully');
      refetch();
    } else {
      message.error('Failed to Retry Webhook');
    }
  };

  const TestWebhook = async () => {
    props.form.validateFields(async (err, values) => {
      console.log('err', err);
      console.log('values', values);

      if (err) {
        return message.warning('Please fill all required fields for testing webhook');
      }

      if (values.headerAuthentication === false) {
        delete values.authHeaderName;
        delete values.authKey;
      }

      setLoading(true);
      const res = await testWebhook(values);
      console.log('res', res);
      setLoading(false);
      if (res.status >= 200 && res.status < 300) {
        message.success('Webhook Test Successfully');
      } else {
        message.error('Failed to Test Webhook');
      }
    });
  };

  const generateCSV = () => {
    if (allList.isLoading === true) {
      return;
    }

    // var order = [...data];
    var order = [...allList.paginatedData];
    var csv_data = [];
    // var sum_balance = 0;

    for (let i = 0; i < order.length; i++) {
      const real_order = order[i];
      // console.log(real_order);
      // sum_balance = parseFloat(
      //   parseFloat(sum_balance + real_order.balance).toFixed(2)
      // );

      csv_data.push({
        createdAt: moment(real_order.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        host: real_order.hostName,
        // ipAddress: real_order.ipAddress,
        url: real_order.url,
        eventType:
          real_order.eventType === 'newReservation'
            ? 'New Reservation'
            : real_order.eventType === 'cancelReservation'
            ? 'Cancel Reservation'
            : real_order.eventType === 'updateReservation'
            ? 'Update Reservation'
            : 'Delete Reservation',
        status: real_order.resStatus === 200 ? 'Success' : 'Failed',
        attempt: real_order.attempt
      });
    }
    // csv_data.push({
    //   balance: sum_balance,
    // });
    set_csv_data(csv_data);
    // this.setState({
    //   csv_data,
    // });
    return true;
  };

  const columns = [
    {
      ...constructColumn('Created At', 'createdAt', {
        hasSorter: true
      }),
      render: (value, record) => {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      ...constructColumn('Host', 'hostName'),
      ...constructColumnFilterSearch('hostName', 'Search Host')
    },
    // {
    //   ...constructColumn('IP Address', 'ipAddress'),
    //   ...constructColumnFilterSearch('ipAddress', 'Search IP Address')
    // },
    {
      ...constructColumn('URL', 'url'),
      ...constructColumnFilterSearch('url', 'Search URL')
    },
    {
      ...constructColumn('Event Type', 'eventType'),
      // ...constructColumnFilterSearch('eventType', 'Search Event Type')
      render: (value, record) => {
        switch (value) {
          case 'newReservation':
            return 'New Reservation';

          case 'cancelReservation':
            return 'Cancel Reservation';

          case 'updateReservation':
            return 'Update Reservation';

          case 'deleteReservation':
            return 'Delete Reservation';

          default:
            break;
        }
      }
    },
    {
      ...constructColumn(
        <div>
          Attempt{' '}
          <Tooltip title="Every 30 minutes will try to attempt, max 5 attempt.">
            <Icon type="info-circle" />
          </Tooltip>
        </div>,
        'attempt'
        // , {
        //   hasSorter: true
        // }
      )
      // ...constructColumnFilterSearch('attempt', 'Search Attempt')
    },
    {
      ...constructColumn('Status', 'resStatus'),
      // ...constructColumnFilterRadio('resStatus', [
      //   {
      //     value: 200,
      //     label: 'Success',
      //     key: 200
      //   },
      //   {
      //     value: 405,
      //     label: 'Failed',
      //     key: 405
      //   }
      // ]),
      // filterMultiple: false,
      // filters: [
      //   {
      //     text: <Tag color="green">Success</Tag>,
      //     value: 'Success'
      //   },
      //   {
      //     text: <Tag color="red">Failed</Tag>,
      //     value: 'Failed'
      //   }
      // ],
      // onFilter: (value, record) => {
      //   // console.log(value, record);

      //   return value === record.status;
      // },
      render: (value, record) => {
        var status = value;

        switch (status) {
          case 200:
            return <Tag color="green">Success</Tag>;

          // case 'failed':
          //   return (
          //     // <Tooltip
          //     //   title={
          //     //     <span>
          //     //       <Icon type="exclamation-circle" /> {record.errorMsg}
          //     //     </span>
          //     //   }
          //     // >
          //     <Tag color="red">Failed</Tag>
          //     // </Tooltip>
          //   );

          default:
            return <Tag color="red">Failed</Tag>;
          // return <Tag color="orange">{status}</Tag>;
        }
      }
    },

    ...(props.checkIsAdmin()
      ? [
          {
            ...constructColumn(
              'Action'
              // 'attempt'
            ),
            render: (value, record) => {
              if (record.resStatus !== 200) {
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      RetryWebhook(record._id);
                    }}
                    disabled={loading}
                  >
                    Retry
                  </Button>
                );
              }
            }
          }
        ]
      : [])
  ];

  const handleOnFormSubmit = e => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (values.headerAuthentication === false) {
        delete values.authHeaderName;
        delete values.authKey;
      }

      console.log('err', err);
      console.log('values', values);

      if (!err) {
        Modal.confirm({
          title: 'Confirm Configure Webhook?',
          onOk() {
            setLoading(true);
            updateWebhookConfigure(values)
              .then(response => {
                console.log('response', response);

                GetWebhookByHost(values.host);

                message.success('Webhook configuration updated successfully');
                setWebhookVisible(false);
                setLoading(false);
              })
              .catch(error => {
                message.error('Failed to update webhook configuration');
                setLoading(false);
              });
          }
        });
      }
    });
  };

  return (
    <StyledCard>
      {webhookVisible && (
        <Modal
          title={`Configure Webhook`}
          style={{ minHeight: '80vh', minWidth: '200px', overflow: 'hidden' }}
          //   width="50vw"
          visible={webhookVisible}
          onCancel={() => {
            setWebhookVisible(false);
          }}
          footer={
            <div>
              <Row type="flex" justify="space-between">
                <Col>
                  <Button
                    key="test"
                    onClick={() => {
                      TestWebhook();
                    }}
                    disabled={loading}
                  >
                    Test Webhook
                  </Button>
                </Col>
                <Col>
                  <Button
                    key="back"
                    onClick={() => {
                      setWebhookVisible(false);
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button key="submit" onClick={handleOnFormSubmit} type="primary" loading={loading}>
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          }
          // footer={[
          //   <Button
          //     key="back"
          //     onClick={() => {
          //       setWebhookVisible(false);
          //     }}
          //   >
          //     Cancel
          //   </Button>,
          //   <Button key="submit" onClick={handleOnFormSubmit} type="primary" loading={loading}>
          //     Save
          //   </Button>
          // ]}
        >
          <Form
            hideRequiredMark

            //    onSubmit={handleOnFormSubmit}
          >
            {/* {props.checkIsAdmin() && ( */}
            <Form.Item label="Host">
              {getFieldDecorator('host', {
                rules: [{ required: true, message: 'Host is required' }]
              })(
                <Select
                  showSearch
                  placeholder="Select host"
                  optionFilterProp="children"
                  onChange={e => {
                    GetWebhookByHost(e);
                  }}
                  size="default"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  // value={selectHost}
                  disabled={loading}
                >
                  {hostList.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            {/* )} */}

            <Form.Item label="Enable Webhook">
              {getFieldDecorator('enabled', {
                initialValue: false,
                valuePropName: 'checked'
                // rules: [{ required: true, message: 'Header Name is required' }]
              })(<Switch disabled={loading} unCheckedChildren="Off" checkedChildren="On" />)}
            </Form.Item>

            <Form.Item label="URL">
              {getFieldDecorator('url', {
                rules: [
                  {
                    type: 'url',
                    message: 'URL is invalid'
                  },
                  { required: true, message: 'URL is required' }
                ]
              })(<Input disabled={loading} />)}
            </Form.Item>

            <Form.Item label="Header Authentication">
              {getFieldDecorator('headerAuthentication', {
                initialValue: false
              })(
                <Radio.Group buttonStyle="solid" disabled={loading}>
                  <Radio.Button value={false}>None</Radio.Button>
                  <Radio.Button value={true}>Yes</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <>
              <Form.Item label="Header Name" style={{ display: getFieldValue('headerAuthentication') === false ? 'none' : 'block' }}>
                {getFieldDecorator('authHeaderName', {
                  rules: [{ required: getFieldValue('headerAuthentication') === false ? false : true, message: 'Header Name is required' }]
                })(<Input disabled={loading} />)}
              </Form.Item>

              <Form.Item label="Header Value" style={{ display: getFieldValue('headerAuthentication') === false ? 'none' : 'block' }}>
                {getFieldDecorator('authKey', {
                  rules: [{ required: getFieldValue('headerAuthentication') === false ? false : true, message: 'Header Value is required' }]
                })(<Input disabled={loading} />)}
              </Form.Item>
            </>

            <Form.Item label="Event">
              {getFieldDecorator('eventType', {
                // initialValue: ['newReservation'],
                rules: [{ required: true, message: 'Please select event' }]
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  disabled={loading}
                >
                  {eventList.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Row type="flex" justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Button
            id="configure-button"
            type="primary"
            // style={{ marginLeft: 'auto', marginRight: '8px' }}
            icon="tool"
            onClick={() => {
              setWebhookVisible(true);

              setTimeout(() => {
                props.form.setFieldsValue(configureData);
              }, 100);
            }}
            disabled={loading || hostList.length === 0}
          >
            Configure Webhook
          </Button>
        </Col>

        <Col>
          <CSVLink data={csv_data} headers={csv_header} filename={`${moment().format('DD-MMM-YYYY')}_Webhook.csv`} onClick={generateCSV}>
            <Button
              id="configure-button"
              type="primary"
              // style={{ marginLeft: 'auto', marginRight: '8px' }}
              icon="cloud-download"
              onClick={() => {
                return;
              }}
              disabled={loading}
            >
              Export CSV
            </Button>
          </CSVLink>
        </Col>
      </Row>

      {/* <Table loading={loading} rowKey={record => record._id} columns={columns} dataSource={data} scroll={{ x: 1000 }} /> */}

      <Table
        columns={columns}
        loading={isGetUserLoading}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        scroll={{ x: true }}
        dataSource={userList}
        rowKey={record => record._id}
        showSorterTooltip={false}
        pagination={{ total, current: query.currentPage }}
      />
    </StyledCard>
  );
};

export default withAppContext(Form.create()(Webhook));
