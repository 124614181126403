import React, { Component } from 'react';
import intl from 'react-intl-universal';
import http from 'axios';
import _ from 'lodash';
import { LocaleProvider as AntDesignLocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import thTH from 'antd/lib/locale/th_TH';
import enUS from 'antd/lib/locale-provider/en_US';
import { getLocaleForUser, setLocaleForDatabase } from './apis/locale';
import { languageOptions } from './constants';

import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/th';

export const LangContext = React.createContext();
const warningHandler = (message, detail) => {}; // Define your custom warning handler

class LocaleProvider extends Component {
  state = {
    initDone: false,
    currentLocale: localStorage.getItem('lang') || 'en-US'
  };

  async componentDidMount() {
    this.loadLocales();
  }

  async loadLocales() {
    let locale;
    const response = (await getLocaleForUser()) || this.state.currentLocale;
    if (response.status === 401) {
      locale = this.state.currentLocale;
    } else {
      locale = response.data;
      this.reloadGoogleMapsAPI();
    }
    if (!_.find(languageOptions, { value: locale })) {
      this.setState({ currentLocale: 'en-US' });
    } else {
      this.setState({ currentLocale: locale });
    }
    localStorage.setItem('lang', this.state.currentLocale);

    http
      .get(`/locales/${this.state.currentLocale}.json`)
      .then(res => {
        //intl.init() - load up the locales for use
        return intl.init({
          warningHandler,
          currentLocale: this.state.currentLocale,
          locales: {
            [this.state.currentLocale]: res.data
          }
        });
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  onSelectLocale = async e => {
    let lang = e;
    if (lang === 'zh-CN') {
      moment.locale('zh-cn');
    } else if (lang === 'th-TH') {
      moment.locale('th');
    } else {
      moment.locale('en');
    }
    localStorage.setItem('lang', lang);
    this.setState({ currentLocale: lang });

    await this.loadLocales();
    await setLocaleForDatabase(lang);
    window.location.reload();
  };

  getLocaleObject = () => {
    if (this.state.currentLocale === 'zh-CN') {
      return zhCN;
    } else if (this.state.currentLocale === 'th-TH') {
      return thTH;
    } else {
      return enUS;
    }
    //return this.state.currentLocale === 'zh-CN' ? zhCN : enUS;
  };

  getLocaleAfterLogin = () => {
    this.loadLocales();
  };

  //maybe try to put it back in html and see ba ?
  reloadGoogleMapsAPI = async () => {
    let locale;
    const response = (await getLocaleForUser()) || this.state.currentLocale;
    if (response.status === 401) {
      locale = this.state.currentLocale;
    } else {
      locale = response.data;
    }
    const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
    if (existingScript) {
      existingScript.remove();
    }

    // Create a new script element with the updated language
    const newScript = document.createElement('script');
    newScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&loading=async&libraries=places&language=${locale}`;
    newScript.async = true;
    newScript.defer = true;
    document.head.appendChild(newScript);
  };

  render() {
    return (
      this.state.initDone && (
        <LangContext.Provider
          value={{
            currentLocale: this.state.currentLocale,
            onSelectLocale: this.onSelectLocale,
            getLocaleAfterLogin: this.getLocaleAfterLogin,
            reloadGoogleMapsAPI: this.reloadGoogleMapsAPI
          }}
        >
          <AntDesignLocaleProvider locale={this.getLocaleObject()}>{this.props.children}</AntDesignLocaleProvider>
        </LangContext.Provider>
      )
    );
  }
}

export const LangContextConsumer = LangContext.Consumer;

export default LocaleProvider;
