import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import { withRouter, Link } from 'react-router-dom';

import { AppContextConsumer } from 'context/AppContext';
import { buildPayoutUri, buildResetExpiredPasswordUri, buildTaskManagementUri } from 'utils/routes';
import { LangContextConsumer } from '../../utils/LocaleProvider';

import './LoginForm.css';

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      isLoggingIn: false,
      wrongCombo: false,
      localeUpdated: false
    };
  }

  componentWillReceiveProps() {
    this.props.form.isFieldsTouched && this.setState({ wrongCombo: false });
  }

  handleSubmit = (contextLoginFn, reloadGoogleMapsAPI) => e => {
    e.preventDefault();
    this.setState({ isLoggingIn: true, wrongCombo: false });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        contextLoginFn(values).then(response => {
          //after contextLoginFn we would get the user if response is success
          if (response.status === 200) {
            const authObj = JSON.parse(localStorage.getItem('auth'));
            //trigger reload of localisation file
            this.setState({ localeUpdated: true }, () => {
              if (authObj && authObj.user && authObj.user.roles.length > 0 && authObj.user.roles[0].permissions.includes(8)) {
                this.props.history.push('/overview');
              } else if (authObj && authObj.user && authObj.user.roles.length > 0 && authObj.user.roles[0].permissions.includes(12)) {
                this.props.history.push(buildPayoutUri());
              } else if (
                authObj &&
                authObj.user &&
                authObj.user.roles.length > 0 &&
                (authObj.user.roles[0].permissions.includes(4) || authObj.user.roles[0].permissions.includes(53))
              ) {
                this.props.history.push('/listing');
              } else if (
                authObj &&
                authObj.user &&
                authObj.user.roles.length > 0 &&
                (authObj.user.roles[0].permissions.includes(60) || authObj.user.roles[0].permissions.includes(62))
              ) {
                this.props.history.push(`${buildTaskManagementUri()}/tasklist`);
              } else if (authObj && authObj.user) {
                this.props.history.push('/overview');
              }
            });
          } else if (response.status === 401) {
            if (response.data && response.data.code === '40001') {
              this.props.history.push(buildResetExpiredPasswordUri({ usernameOrEmail: values.email }));
            } else {
              this.setState({ wrongCombo: true, isLoggingIn: false });
            }
          }
        });
      } else if (err) {
        this.setState({ isLoggingIn: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <AppContextConsumer>
        {({ login }) => (
          <LangContextConsumer>
            {({ reloadGoogleMapsAPI, getLocaleAfterLogin }) => (
              <Form onSubmit={this.handleSubmit(login, reloadGoogleMapsAPI)} className="login-form">
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your Username/Email.'
                      }
                    ]
                  })(<Input type="text" autoComplete="email" prefix={<Icon type="user" />} placeholder={'Username/Email'} />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your password.'
                      }
                    ]
                  })(<Input.Password prefix={<Icon type="lock" />} type="password" autoComplete="current-password" placeholder={'Password'} />)}
                </FormItem>
                {this.state.wrongCombo && <Alert message={'Invalid email/password combination.'} type="error" showIcon />}
                <FormItem>
                  <Link className="login-form-forgot" to={'/forgot-password'}>
                    {'Forgot Password'}
                  </Link>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.isLoggingIn}>
                    {'Log in'}
                  </Button>
                </FormItem>
                {this.state.localeUpdated && getLocaleAfterLogin && getLocaleAfterLogin()}
              </Form>
            )}
          </LangContextConsumer>
        )}
      </AppContextConsumer>
    );
  }
}

export default withRouter(Form.create()(LoginForm));
