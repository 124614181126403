import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import FormPassword from 'components/FormPassword/FormPassword.js';

import { postSignUp } from '../../utils/apis/authentication.js';
import './SignUpForm.css';
const FormItem = Form.Item;

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSigningUp: false,
      errorMsg: ''
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSigningUp: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        postSignUp(values)
          .then(res => {
            res.status === 200 && this.props.history.push('/login');
            res.status === 422 && this.setState({ errorMsg: res.data.error, isSigningUp: false });
          })
          .catch(error => {
            this.setState({
              errorMsg: 'Something went wrong! Please directly contact our Customer Service for assistance.',
              isSigningUp: false
            });
          });
      } else {
        this.setState({
          isSigningUp: false
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="signup-form">
        <FormItem>
          {getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                message: 'Please enter your first name!'
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={'forms.placeholders.firstName'} />)}
        </FormItem>

        <FormItem>
          {getFieldDecorator('lastName', {
            rules: [
              {
                required: true,
                message: 'Please enter your last name!'
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={'Last Name'} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: 'Please enter your last name!'
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={'Username'} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Please enter your email!'
              },
              {
                type: 'email',
                message: 'You have entered an invalid email!'
              }
            ]
          })(<Input prefix={<Icon type="mail" />} placeholder={'Email'} />)}
        </FormItem>
        <FormPassword form={this.props.form} shouldHideLabel />
        <FormItem>
          {getFieldDecorator('icNo', {
            rules: [
              {
                required: true,
                message: 'Please enter your NRIC/Passport Number.'
              }
            ]
          })(<Input prefix={<Icon type="idcard" />} placeholder={'NRIC/Passport Number'} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('contactNo', {
            rules: [
              {
                required: true,
                message: 'Please enter your contact number.'
              }
            ]
          })(<Input prefix={<Icon type="phone" />} type="tel" placeholder={'Contact Number'} />)}
        </FormItem>
        {this.state.errorMsg && <Alert message={this.state.errorMsg} type="error" showIcon />}
        <FormItem>
          <Button type="primary" htmlType="submit" className="signup-form-button" loading={this.state.isSigningUp}>
            {'Sign Up'}
          </Button>
          {'Already have an account? '}
          <Link to={'/login'}>{'Login now!'}</Link>
        </FormItem>
      </Form>
    );
  }
}

export default withRouter(Form.create()(SignUpForm));
