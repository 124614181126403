import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Badge, Button, Icon, Layout, Modal, Row, Tooltip, Dropdown, Menu, Image } from 'antd';
import { GlobalOutlined } from '@ant-design/icons'; // Import the icon you want to use

import { withAppContext } from 'context/AppContext';
import { getUserProfileById } from 'utils/apis/user';
import { DEFAULT_USER_PROFILE_URL } from 'utils/constants';
import { guard } from 'utils/general';
import { buildAPBillingInventoryUri, buildHostBookingEngineUrl, buildHostHotelBookingEngineUrl } from 'utils/routes';
import { REACT_APP_IS_SHOW_INVENTORY } from 'config/env';
import { LangContextConsumer } from '../../utils/LocaleProvider';
import { languageOptions } from '../../utils/constants';

import './Header.css';
import intl from 'react-intl-universal';

const AnnouncementsButton = ({ className, onClick, shouldShowAnnouncementDot }) => {
  return (
    <div className={className} onClick={onClick}>
      <Badge dot={shouldShowAnnouncementDot}>
        <Icon type="notification" theme="outlined" className="announcement-button-icon" />
      </Badge>
    </div>
  );
};

const AnnouncementsModal = ({ announcements, visible, onClose }) => {
  return (
    <Modal
      style={{ top: 20 }}
      bodyStyle={{
        maxHeight: '75vh',
        overflowY: 'auto'
      }}
      title={intl.get('header.headerLabels.whatsNew').d("What's New")}
      visible={visible}
      okText={intl.get('header.headerLabels.awesome').d('Awesome')}
      cancelText={intl.get('header.headerLabels.gotIt').d('Got it!')}
      onOk={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      {announcements.map(announcement => {
        return (
          <div className="announcement-post" key={announcement._id}>
            <ReactMarkdown source={announcement.message} />
          </div>
        );
      })}
    </Modal>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: DEFAULT_USER_PROFILE_URL,
      isAnnouncementModalVisible: false,
      shouldShowAnnouncementDot: true
    };
  }

  componentDidMount() {
    const { user, announcements } = this.props;
    getUserProfileById(user._id)
      .then(res => {
        if (res.data.userProfile && res.data.userProfile.displayPicture && res.data.userProfile.displayPicture.imageUrl) {
          this.setState({
            imageUrl: res.data.userProfile.displayPicture.imageUrl
          });
        }
      })
      .catch(err => {});

    if (announcements && announcements.length > 0 && announcements[0]._id !== localStorage.getItem('latestAnnouncementReadId')) {
      this.setState({ isAnnouncementModalVisible: true });
      localStorage.setItem('latestAnnouncementReadId', announcements[0]._id);
    } else {
      this.setState({ shouldShowAnnouncementDot: false });
    }
  }

  handleOnAdminPanelButtonClick = () => {
    const { history } = this.props;
    history.push(buildAPBillingInventoryUri());
  };

  handleOnAnnouncementModalOpen = () => {
    this.setState({ isAnnouncementModalVisible: true, shouldShowAnnouncementDot: false });
  };

  handleOnAnnouncementModalClose = () => {
    this.setState({ isAnnouncementModalVisible: false, shouldShowAnnouncementDot: false });
  };

  render() {
    const { imageUrl, isAnnouncementModalVisible, shouldShowAnnouncementDot } = this.state;
    const { user, announcements, isAllowedBookingEngine, isAllowedHotelBookingEngine, checkIsAdmin, checkIsAdminReadOnly, isCollapsed } = this.props;
    const userProfileNavClassNames = isCollapsed ? 'header-firstname' : 'header-firstname no-show';
    const announcementButtonNavClassNames = isCollapsed ? 'announcement-button' : 'announcement-button no-show';

    const hostId = guard(() => (isAllowedBookingEngine || isAllowedHotelBookingEngine ? user.roles[0].host : ''), '');
    const handleMenuClick = e => {
      console.log('Clicked on menu item:', e.key);
    };

    return (
      <Layout.Header className="flex">
        <span>
          {checkIsAdmin() && !checkIsAdminReadOnly() && (
            <Button className="adminPanelButton" onClick={this.handleOnAdminPanelButtonClick}>
              To Admin Panel
            </Button>
          )}
        </span>
        <Row type="flex" align="middle">
          {announcements && announcements.length > 0 && (
            <>
              <Tooltip title={intl.get('header.headerLabels.whatsNew').d("What's New")}>
                <AnnouncementsButton
                  className={announcementButtonNavClassNames}
                  onClick={() => {
                    this.handleOnAnnouncementModalOpen();
                  }}
                  shouldShowAnnouncementDot={shouldShowAnnouncementDot}
                />
              </Tooltip>
              <AnnouncementsModal
                visible={isAnnouncementModalVisible}
                announcements={announcements}
                onClose={this.handleOnAnnouncementModalClose}
              ></AnnouncementsModal>
            </>
          )}
          {isAllowedBookingEngine && (
            <a href={buildHostBookingEngineUrl(hostId)} target="_blank" rel="noopener noreferrer">
              <Tooltip title={intl.get('header.headerLabels.homeBooking').d('Your Homes Booking Website')}>
                <Icon type="layout" className="booking-engine-icon" />
              </Tooltip>
            </a>
          )}
          {isAllowedHotelBookingEngine && (
            <a href={buildHostHotelBookingEngineUrl(hostId)} target="_blank" rel="noopener noreferrer">
              <Tooltip title={intl.get('header.headerLabels.hotelBooking').d('Your Hotel Booking Website')}>
                <Icon type="layout" className="booking-engine-icon" />
              </Tooltip>
            </a>
          )}

          <Link to={`/notifications`} className={userProfileNavClassNames}>
            <Icon type="bell" className="notifications" />
          </Link>

          <Link to={`/userProfile/${user._id}`} className={userProfileNavClassNames}>
            <Tooltip title={intl.get('header.headerLabels.userProfile').d('User Profile')}>
              <img className="avatar" src={imageUrl} id="image" title="User profile" alt="User Icon" />
              {intl.get('header.headerLabels.hi').d('Hi,')} {user.firstName}!
            </Tooltip>
          </Link>

          <LangContextConsumer>
            {({ currentLocale, onSelectLocale }) => {
              const handleMenuClick = e => {
                onSelectLocale(e.key); // Pass the selected language key to the parent component
              };
              const langMenu = (
                <Menu onClick={handleMenuClick} defaultSelectedKeys={[currentLocale]}>
                  {languageOptions.map((option, index) => (
                    <Menu.Item key={option.key}>
                      <img src={option.flagUrl} alt={option.label} style={{ marginRight: '15px', width: '20px', height: 'auto' }} />
                      {option.label}
                    </Menu.Item>
                  ))}
                </Menu>
              );
              return (
                <Dropdown overlay={langMenu} placement="bottomRight">
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <GlobalOutlined style={{ fontSize: '17px', marginLeft: '15px', paddingTop: '25px' }} />{' '}
                  </a>
                </Dropdown>
              );
            }}
          </LangContextConsumer>
        </Row>
      </Layout.Header>
    );
  }
}

export default withRouter(withAppContext(Header));
