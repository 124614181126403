import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Button, Col, Card, Form, Skeleton, notification } from 'antd';
import queryString from 'query-string';

import FormInput from 'components/FormInput/FormInput';
import FormPassword from 'components/FormPassword/FormPassword';

import { getCheckIsUserPasswordExpired, postChangeExpiredPassword } from 'utils/apis/authentication';
import { guard } from 'utils/general';

import SingleViewLayout from './../../layouts/SingleViewLayout/SingleViewLayout';

const FormItem = Form.Item;

const ResetExpiredPassword = ({ form, history }) => {
  const [isCheckingOldPassword, setIsCheckingOldPassword] = useState(true);
  const [isOldPasswordExpired, setIsOldPasswordExpired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const query = useMemo(() => queryString.parse(history.location.search), [history.location.search]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getCheckIsUserPasswordExpired(query.username).catch(ex => {
        console.error(ex);
        return { isPasswordExpired: false };
      });
      setIsCheckingOldPassword(false);
      setIsOldPasswordExpired(response.isPasswordExpired);
    };

    fetch();
  }, [query]);

  const handleOnFormSubmit = event => {
    event.preventDefault();
    form.validateFields((errors, values) => {
      if (!errors) {
        setIsSubmitting(true);
        postChangeExpiredPassword(values.username, values.password)
          .then(() => {
            notification.success({
              duration: 3,
              message: 'Success',
              description: `You've successfully change your password. You can login with your new password now.`
            });
            history.push('/login');
          })
          .catch(ex => {
            const errorMessage = guard(() => ex.response.data.message, ex.message);
            notification.error({
              duration: 3,
              message: `Error`,
              description: errorMessage
            });
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    });
  };

  return (
    <SingleViewLayout className="centralise">
      <Col xs={22} sm={16} md={14} lg={10} xl={8}>
        <Card title={'Reset Password'}>
          {isCheckingOldPassword && <Skeleton loading={isCheckingOldPassword} />}
          {!isCheckingOldPassword && isOldPasswordExpired && (
            <Form onSubmit={handleOnFormSubmit}>
              <Alert
                message={`Password Expired`}
                description={
                  <span>
                    Your password is expired and you are required to change your password for better account security. Your new password should be{' '}
                    <strong>8-20 alphanumeric</strong>, with at least <strong>1 lowercase</strong>, <strong>1 uppercase</strong> and{' '}
                    <strong>1 special character (!@#$?. only without space)</strong>`
                  </span>
                }
                type="error"
                showIcon
              />
              <FormInput formLabel={`Your username`} form={form} name="username" disabled defaultValue={query.username} />
              <FormPassword form={form} isResetPassword />
              <FormItem>
                <Button type="primary" htmlType="submit" loading={isSubmitting} block>
                  Reset
                </Button>
              </FormItem>
            </Form>
          )}
          {!isCheckingOldPassword && !isOldPasswordExpired && <p>Your password is not expired</p>}
        </Card>
      </Col>
    </SingleViewLayout>
  );
};

export default Form.create()(ResetExpiredPassword);
